import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ContactForm } from '../../forms/contactForm';

const ContactBlock = ({ blockConfig, contactDetails }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter grid grid-cols-1 md:grid-cols-3">
      <PortableTextBlock text={contactDetails} className="prose prose-lg md:col-span-1" />
      <div className="md:col-start-2 md:col-span-2">
        <ContactForm />
      </div>
    </BlockWrapper>
  );
};

export default ContactBlock;
