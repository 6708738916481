import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';

const ImageBlock = ({ blockConfig, image, layout, showLine }) => {
  const layoutClasses = () => {
    switch (layout) {
      case 'left':
        return 'col-start-1 col-end-14 md:col-end-13';
      case 'right':
        return 'col-start-2 col-end-15 md:col-start-3';
      case 'full':
        return 'col-start-1 col-end-15';
      default:
        return 'col-start-2 col-end-14';
    }
  };
  const lineClasses = () => {
    switch (layout) {
      case 'left':
        return 'col-start-14 -ml-2 md:col-start-13 lg:-ml-3';
      case 'right':
        return 'col-start-2 -ml-2 md:col-start-3 lg:-ml-3';
      default:
        return 'col-start-2 col-end-14 justify-self-center';
    }
  };

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      {showLine && (
        <span
          className={`w-6 h-20 bg-pull-color self-center relative z-20 row-start-1 ${lineClasses()} lg:h-60 lg:w-8`}
        />
      )}
      <div className={`${layoutClasses()} row-start-1`}>
        {image && <Image {...image} width="2000" className="w-full" />}
      </div>
    </BlockWrapper>
  );
};

export default ImageBlock;
