import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useAppContext } from '../../../state';

import { AnimatePresence, motion } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: '0px' },
};

const ServiceItemDesk = ({ title, serviceText, _key, expanded, setExpanded }) => {
  const isOpen = _key === expanded;
  const { setCursorHover } = useAppContext();
  return (
    <motion.div className="grid grid-cols-accordion self-start">
      <AnimatePresence>
        {isOpen && <motion.span className="w-3 col-start-1 bg-pull-color" />}
      </AnimatePresence>

      <div className="col-start-2">
        <h4
          onClick={() => setExpanded(_key)}
          className="font-black text-2xl pb-2 cursor-pointer"
          onMouseEnter={() => setCursorHover(true)}
          onMouseLeave={() => setCursorHover(false)}
        >
          {title}
        </h4>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={{ duration: 0.2, ease: 'easeIn' }}
              key={_key}
              className="prose prose-lg"
            >
              <p>{serviceText}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const ServiceItemMobile = ({ title, serviceText }) => {
  return (
    <div className="flex justify-between border-l-4 border-dejima-red">
      <div className="prose pl-6">
        <h3 className="font-medium">{title}</h3>
        <p>{serviceText}</p>
      </div>
    </div>
  );
};

const ServicesBlock = ({ blockText, blockConfig, blockImage, items, topographicMap }) => {
  const [expanded, setExpanded] = useState(items[0]._key);
  const breakpoints = useBreakpoint();

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 gap-y-10">
      <div className="col-start-2 col-end-14 md:col-end-8 row-start-1 relative z-10">
        <PortableTextBlock text={blockText} className="prose prose-lg" />
      </div>
      {breakpoints.xs || breakpoints.sm ? (
        <div className="col-start-2 col-end-14 grid gap-y-6 row-start-2 relative z-10">
          {items.map((item) => (
            <ServiceItemMobile {...item} />
          ))}
        </div>
      ) : (
        <div className="col-start-2 md:col-end-6 grid gap-y-8 self-start mt-10 row-start-2 relative z-10">
          {items.map((item) => (
            <ServiceItemDesk {...item} expanded={expanded} setExpanded={setExpanded} />
          ))}
        </div>
      )}

      <div className="col-start-2 col-end-14 row-start-1 md:col-start-7 md:col-end-15 md:row-start-2 relative z-10">
        {blockImage && blockImage.asset && <Image {...blockImage} />}
      </div>
      {/* Map */}
      <div className="col-start-5 col-end-14 row-start-1 row-end-4 z-0 relative">
        {topographicMap && topographicMap.asset && <Image {...topographicMap} />}
      </div>
    </BlockWrapper>
  );
};

export default ServicesBlock;
