import React from 'react';
import PropTypes from 'prop-types';

import {
  BannerOne,
  TextBlock,
  ImageBlock,
  EditorialOne,
  CaseCarousel,
  ContactBlock,
  EditorialTwo,
  BioGrid,
  ServicesBlock,
  PartnersGrid,
  ResponsiveImageBlock,
  QuoteBlock,
} from './blocks';

const BlockZone = ({ blocks, pageTheme }) => {
  const block = blocks.map((block) => {
    switch (block._type) {
      case 'bannerOne':
        return <BannerOne {...block} />;
      case 'editorialOne':
        return <EditorialOne {...block} />;
      case 'editorialTwo':
        return <EditorialTwo {...block} />;
      case 'caseCarousel':
        return <CaseCarousel {...block} />;
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'imageBlock':
        return <ImageBlock {...block} />;
      case 'bioGrid':
        return <BioGrid {...block} />;
      case 'contactBlock':
        return <ContactBlock {...block} />;
      case 'servicesBlock':
        return <ServicesBlock {...block} />;
      case 'partnersGrid':
        return <PartnersGrid {...block} />;
      case 'responsiveImageBlock':
        return <ResponsiveImageBlock {...block} />;
      case 'quoteBlock':
        return <QuoteBlock {...block} />;
      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
