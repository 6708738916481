import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ResponsiveImage } from '../../media/responsiveImage';

const EditorialSection = ({ sectionImage, sectionText, reversed, title }) => {
  const imageClasses = reversed
    ? 'md:col-start-7 md:col-end-13 md:row-start-2 md:row-end-4'
    : 'md:col-start-1 md:col-end-7 md:row-start-1 md:row-end-3';

  const textClasses = reversed
    ? 'md:col-start-1 md:col-end-7 md:row-start-3'
    : 'md:col-start-7 md:col-end-13 md:row-start-1';
  return (
    <>
      <div className={`prose prose-lg col-start-1 col-end-13 ${textClasses} relative z-10`}>
        <h1 className="font-medium">{title}</h1>
        <div className="grid grid-flow-col gap-x-10">
          <span className="w-6 h-24 bg-pull-color self-start mt-8" />
          <PortableTextBlock text={sectionText} />
        </div>
      </div>
      <div className={`col-start-1 col-end-13 ${imageClasses} relative z-10`}>
        <ResponsiveImage {...sectionImage} />
      </div>
    </>
  );
};

const EditorialOne = ({ blockConfig, sectionOne, sectionTwo, topographicMap }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className="grid grid-cols-12 md:grid-rows-editorialOne gap-y-10 px-gutter w-full md:gap-x-5 lg:gap-x-16 lg:gap-y-20 relative"
    >
      {/* Section One */}
      <EditorialSection {...sectionOne} />
      {/* Section Two */}
      <EditorialSection {...sectionTwo} reversed />
      {/* Topographic Map */}
      <div className="col-start-1 col-end-11 row-start-2 row-end-3 self-end -mb-6 -ml-gutter hidden lg:block">
        {topographicMap && topographicMap.asset && <Image {...topographicMap} />}
      </div>
    </BlockWrapper>
  );
};

export default EditorialOne;
