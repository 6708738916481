import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

// Hooks
import { usePadTop, usePadBottom } from '../../hooks';
import { useZoneContext } from '../blockZone/zone.development';

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  blockTheme,
}) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    const focusedTheme = inView ? blockTheme : null;
    if (focusedTheme) {
      const themeArray = Object.entries(focusedTheme);
      themeArray.forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, `${value.hex}`);
      });
    }
  }, [inView, blockTheme]);

  return (
    <section
      className={`${className && className} ${padTop && padTop} ${padBottom && padBottom}`}
      id={slug && slug.current}
      ref={ref}
    >
      {children}
    </section>
  );
};
