import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';
import { ResponsiveImage } from '../../media/responsiveImage';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const EditorialTwo = ({
  blockConfig,
  heading,
  layout,
  blockText,
  blockImage,
  bottomImage,
  topographicMap,
}) => {
  const imageClasses =
    layout === 'left'
      ? 'col-start-1 col-end-14 md:col-end-12'
      : layout === 'right'
      ? 'col-start-2 col-end-15 md:col-start-5'
      : 'col-start-2 col-end-14';

  const headingClasses =
    layout === 'left'
      ? 'md:col-start-3 md:col-end-11'
      : layout === 'right'
      ? 'md:col-start-5 md:col-end-13'
      : '';
  const textClasses =
    layout === 'left'
      ? 'md:col-start-3 md:col-end-11'
      : layout === 'right'
      ? 'md:col-start-5 md:col-end-13'
      : '';

  const lineClasses = layout === 'left' ? 'md:col-start-12' : 'md:col-start-5';

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 grid-rows-bannerTwo gap-y-10">
      <div className="row-start-1" />
      <div className={`col-start-2 col-end-14 row-start-1 self-end ${headingClasses}`}>
        <h1 className="text-3xl md:text-5xl lg:text-7xl font-medium">{heading}</h1>
      </div>
      <div className={`${imageClasses} row-start-2 relative z-10`}>
        {blockImage && <ResponsiveImage {...blockImage} />}
      </div>
      <div className={`col-start-2 col-end-14 ${textClasses} relative z-10 row-start-3`}>
        <PortableTextBlock text={blockText} className="prose prose-lg" />
      </div>
      <span
        className={`w-6 h-20 bg-pull-color row-start-2 col-start-2 -ml-3 self-end mb-6 relative z-20 ${lineClasses} lg:w-8`}
      />
      <div className={`col-start-1 col-end-13 row-start-4 mt-10 relative z-10`}>
        {bottomImage && <ResponsiveImage {...bottomImage} />}
      </div>
      {/* Illustration */}
      <div className="col-start-2 col-end-14 row-start-3 row-end-5 self-center z-0 relative">
        {topographicMap && topographicMap.asset && <Image {...topographicMap} />}
      </div>
    </BlockWrapper>
  );
};

export default EditorialTwo;
