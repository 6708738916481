import React from 'react';
import { BlockWrapper } from '../blockWrapper';

import { ResponsiveImage } from '../../media/responsiveImage';

const BannerOne = ({
  blockConfig,
  heading,
  bannerImage,
  imagePosition,
  textPosition,
  themeDark,
}) => {
  const imageClasses =
    imagePosition === 'left' ? 'col-start-1 col-end-14' : 'col-start-2 col-end-15';

  const textClasses = textPosition === 'left' ? 'md:col-end-8' : 'md:col-start-8';

  const lineClasses =
    imagePosition === 'left' ? 'col-start-14 -ml-2 lg:-ml-3' : 'col-start-2 -ml-2 lg:-ml-3';

  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14 gap-y-10 grid-rows-bannerTwo">
        <span
          className={`w-6 h-20 bg-pull-color row-start-2 row-end-4 self-center relative z-20 ${lineClasses} lg:h-60 lg:w-8`}
          data-scroll-speed="2"
          data-scroll
        />
        {themeDark && (
          <span className="bg-dejima-green col-start-1 col-end-15 row-start-1 row-end-3" />
        )}
        <div
          className={`col-start-2 col-end-14 ${textClasses} row-start-1 self-end ${
            themeDark ? 'text-white' : 'text-dejima-text'
          }`}
        >
          <h1 className="text-3xl md:text-5xl xl:text-7xl font-medium">{heading}</h1>
        </div>
        <div className={`${imageClasses} row-start-2 row-end-4`}>
          <ResponsiveImage {...bannerImage} className="w-full" />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default BannerOne;
