import React from 'react';
import PortableText from 'react-portable-text';

// Block Components
import {
  AnchorScrollLink,
  ExternalLink,
  PageAnchorLink,
  PortablePageLink,
  PathLink,
} from '../ui/links';
import { ImageModule, VideoModule } from '../modules';

const serializers = {
  h1: (props) => <h1 {...props} />,
  h2: (props) => <h2 {...props} />,
  h3: (props) => <h3 {...props} />,
  h4: (props) => <h4 {...props} />,
  h5: (props) => <h5 {...props} />,
  h6: (props) => <h6 {...props} />,
  'super-script': (props) => <sup>{props.children}</sup>,
  'sub-script': (props) => <sub>{props.children}</sub>,
  pageLink: (props) => <PortablePageLink {...props} className="underline " />,
  pathLink: (props) => <PathLink {...props} className="underline" />,
  anchorLink: (props) => <AnchorScrollLink {...props} className="underline" />,
  externalLink: (props) => <ExternalLink {...props} className="underline" />,
  pageAnchorLink: (props) => <PageAnchorLink {...props} className="underline" />,
  normal: (props) => <p {...props} />,
  imageModule: (props) => <ImageModule {...props} />,
  videoModule: (props) => <VideoModule {...props} />,
}; // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
  return <PortableText content={text} serializers={serializers} {...props} />;
};
