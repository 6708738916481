import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { BlockWrapper } from '../blockWrapper';

const PartnersItem = ({ image }) => {
  return <div>{image && image.asset && <Image {...image} className="w-24 mx-auto" />}</div>;
};

const PartnersGrid = ({ items, title, blockConfig }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 my-10">
      <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-12">
        <h2 className="text-xl font-medium py-2 border-b border-dejima-green border-opacity-50">
          {title}
        </h2>
        <div className="grid grid-cols-3 gap-10 lg:grid-cols-6 py-10">
          {items.map((item) => (
            <PartnersItem {...item} />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default PartnersGrid;
