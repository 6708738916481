import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { useEmblaCarousel } from 'embla-carousel/react';

import { useAppContext } from '../../../state';

import { BlockWrapper } from '../blockWrapper';

const CaseCard = ({ carouselImage, slug, title, excerpt, createdDate }) => {
  const { setCursorState } = useAppContext();

  return (
    <Link
      to={`/case-studies/${slug && slug.current}`}
      className="w-72 md:w-96 pl-10"
      style={{ flex: '0 0 auto' }}
      onMouseEnter={() => setCursorState('case')}
      onMouseLeave={() => setCursorState('default')}
      onClick={() => setCursorState('default')}
    >
      <div className="grid grid-flow-col">
        <h4 className="text-pull-color vert-text font-medium row-start-1">{createdDate}</h4>
        {carouselImage && carouselImage.asset && (
          <Image {...carouselImage} className="row-start-1" />
        )}
        <div className="mt-4 prose prose-lg row-start-2">
          <h3 className="font-medium">{title}</h3>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  );
};

const CaseCarousel = ({ blockConfig, items, heading }) => {
  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });
  return (
    <BlockWrapper {...blockConfig} className="overflow-hidden cursor-drag">
      <div className="px-gutter pb-16 md:flex justify-between w-full max-w-none">
        <h2 className="font-medium text-4xl mb-5 md:mb-0">{heading}</h2>
        <Link to="/case-studies" className="dj-btn self-center">
          View Work
        </Link>
      </div>
      <div className="px-gutter" ref={emblaRef}>
        <div className="flex -ml-10">
          {items.map((item) => (
            <CaseCard {...item} />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default CaseCarousel;
