import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { useAppContext } from '../../../state';

const BioCard = ({ firstName, lastName, bioImage, bio, socialHandles, isOdd }) => {
  const { setCursorHover } = useAppContext();

  return (
    <div
      className={`grid grid-cols-bioCard grid-rows-bioCard ${!isOdd && 'md:mt-20'} md:self-start`}
    >
      <div className="row-start-2 col-start-1 z-10">
        <h2
          className="text-3xl text-pull-color font-black leading-tight md:text-5xl"
          data-scroll-speed="-0.5"
          data-scroll
        >
          {firstName} <br />
          {lastName}
        </h2>
      </div>
      <div className="row-start-1 row-end-3 col-start-2">
        <div className="aspect-w-1 aspect-h-1 relative">
          {bioImage && bioImage.asset && (
            <Image {...bioImage} className="asolute h-full w-full object-cover" />
          )}
        </div>
      </div>
      <div className=" row-start-3 col-start-2">
        <div className="prose prose-lg">
          <PortableTextBlock text={bio} />
        </div>
        <div className="flex">
          {socialHandles.map(({ link, icon }) => (
            <a
              href={link}
              target="_blank"
              className="w-8 mr-4"
              onMouseEnter={() => setCursorHover(true)}
              onMouseLeave={() => setCursorHover(false)}
            >
              <div
                dangerouslySetInnerHTML={{ __html: icon }}
                className="text-dejima-text fill-current svg"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

const BioGrid = ({ blockConfig, heading, items }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter">
      <h2 className="text-3xl md:text-5xl font-medium">{heading}</h2>
      <div className="grid grid-cols-1 gap-10 mt-10 md:grid-cols-2 md:gap-20 md:mt-20">
        {items.map((item, index) => (
          <BioCard {...item} isOdd={index % 2 == 0} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default BioGrid;
