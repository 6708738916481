import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextBlock = ({ _rawText, centerText, blockConfig, layout }) => {
  const layoutClasses =
    layout === 'left'
      ? 'md:col-start-3 md:col-end-11'
      : layout === 'right'
      ? 'md:col-start-6 md:col-end-13'
      : 'md:col-start-3 md:col-end-12';

  return (
    <BlockWrapper className="grid grid-cols-14" {...blockConfig}>
      <div className={`col-start-2 col-end-14 ${layoutClasses}`}>
        {_rawText && (
          <PortableTextBlock
            text={_rawText}
            className={`prose prose-lg max-w-none ${centerText && 'text-center'}`}
          />
        )}
      </div>
    </BlockWrapper>
  );
};

export default TextBlock;
