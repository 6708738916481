import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { ResponsiveImage } from '../../media/responsiveImage';

const ResponsiveImageBlock = ({ image, blockConfig }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter w-full">
      {image && <ResponsiveImage {...image} className="w-full" />}
    </BlockWrapper>
  );
};

export default ResponsiveImageBlock;
