import React from 'react';

import QuoteTop from '../../../svg/quote-top.svg';
import QuoteBottom from '../../../svg/quote-bottom.svg';

import { BlockWrapper } from '../blockWrapper';

const QuoteBlock = ({ blockConfig, quote, author }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14 mb-4 md:col-start-4 md:col-end-6">
        <h4 className="font-bold">{author}</h4>
      </div>
      <div className="col-start-2 col-end-14 relative p-4 md:col-start-7 md:col-end-12">
        <QuoteTop className="fill-current opacity-5 w-12 absolute top-0 left-0 z-0 md:w-14" />
        <h2 className="text-2xl italic z-10 relative leading-relaxed">{quote}</h2>
        <QuoteBottom className="w-12 fill-current opacity-5 absolute bottom-0 right-0 z-0 md:w-14" />
      </div>
    </BlockWrapper>
  );
};

export default QuoteBlock;
